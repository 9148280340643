<template>
<!-- 动态主页 -->
  <div id="appContainer">
    <div
      class="cson-container"
      :style="{ height: computedHeight }"
      v-infinite-scroll="load"
    >
      <div class="left-container">
        <div class="userInfo" v-if="userInfo && userInfo.id">
          <router-link :to="`/userPage?userId=${userInfo.id}`">
            <div class="userAvatar">
              <img :src="userInfo.avatar" alt="" />
            </div>
          </router-link>
          <div class="userName">{{ userInfo.username }}</div>
          <div
            class="userSign"
            v-if="userInfo.introduction && userInfo.introduction != ''"
          >
            {{ userInfo.introduction }}
          </div>
          <div class="focus">
            <div class="focus-person">
              <div>我关注的人</div>
              <div class="count">{{ trendsStatistics.user }}</div>
            </div>
            <div class="focus-company">
              <div>我关注的公司</div>
              <div class="count">{{ trendsStatistics.company }}</div>
            </div>
          </div>
        </div>
        <div class="about-company">
          <div class="title">相关公司推荐</div>
          <div
            class="companies"
            v-for="(company, index) in trendsStatistics.companies"
            :key="index"
          >
            <el-row :gutter="8">
              <el-col :span="6">
                <img :src="company.logo" class="companyLogo" alt="" />
              </el-col>
              <el-col :span="11">
                <div class="companyName">{{ company.name }}</div>
                <div class="follows">{{ company.concernNum }} Follow</div>
              </el-col>
              <el-col :span="4">
                <div
                  class="followBtn"
                  v-if="!company.concern"
                  @click="focusCompany(company.id)"
                >
                  关注
                </div>
                <div
                  class="followBtn grey"
                  v-else
                  @click="focusCompany(company.id)"
                >
                  已关注
                </div>
              </el-col>
            </el-row>
          </div>
          <router-link to="/companyList">
            <div class="more">查看更多</div>
          </router-link>
        </div>
      </div>
      <div class="middle-container">
        <div class="button-container">
          <div
            :class="['activeTab', activeTab == 'trends' ? 'active' : '']"
            @click="switchTab('trends')"
          >
            动态
          </div>
          <div
            :class="['activeTab', activeTab == 'findJobs' ? 'active' : '']"
            @click="switchTab('findJobs')"
          >
            北美求职
          </div>
        </div>
        <div v-if="activeTab == 'trends'" class="trends">
          <div class="publishArea">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="发动态" name="first">
                <trends-publish
                  @updateTrendsList="getTrendsList"
                ></trends-publish>
              </el-tab-pane>
              <!-- <el-tab-pane label="发面经" name="second"></el-tab-pane> -->
            </el-tabs>
          </div>
          <el-divider content-position="right">
            <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link">
                {{ trendsDropText
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="all">全部</el-dropdown-item>
                <el-dropdown-item command="focus">关注</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-divider>
          <div class="trendsList">
            <div
              class="trendsComment"
              v-for="(trend, index) in trendsList"
              :key="index"
              @click.stop="viewTrends(trend)"
            >
              <div>
                <el-row :gutter="16">
                  <el-col :span="2" style="text-align: right">
                    <el-popover
                      v-if="trend.avatar"
                      placement="right"
                      width="300"
                      trigger="hover"
                      @show="getHoverUserInfo(trend.userId)"
                    >
                      <div class="hoverUserInfo">
                        <el-row :gutter="6">
                          <el-col :span="4">
                            <img
                              class="hoverAvatar"
                              :src="hoverUserInfo.avatar"
                              alt=""
                            />
                          </el-col>
                          <el-col :span="20">
                            <div class="hoverUserName">
                              {{ hoverUserInfo.userName }}
                            </div>

                            <div class="hoverDes">
                              {{ hoverUserInfo.introduction }}
                            </div>
                            <el-button
                              v-if="
                                !userInfo.id ||
                                hoverUserInfo.userId != userInfo.id
                              "
                              @click="focusUser(hoverUserInfo.userId)"
                              class="hoverConcern"
                              size="small"
                              round
                              :type="hoverUserInfo.concern ? 'info' : 'primary'"
                              >{{ hoverUserInfo.concern ? "已关注" : "关注" }}
                            </el-button>
                          </el-col>
                        </el-row>
                      </div>
                      <div slot="reference">
                        <img
                          :src="trend.avatar"
                          class="userAvatar"
                          alt=""
                          @click.stop="jumpUserPage(trend.userId)"
                        />
                      </div>
                    </el-popover>
                    <div v-else class="anonymous">匿名</div>
                  </el-col>

                  <el-col
                    :span="22"
                    v-if="
                      trend.type.value == 1 ||
                      trend.type.value == 5 ||
                      trend.type.value == 6
                    "
                  >
                    <div class="username">
                      {{ trend.username ? trend.username : "匿名用户" }}
                      <span class="createTime">{{ trend.createTime }}</span>
                      <span
                        class="trendType orange"
                        v-if="trend.content.companyName"
                        >{{ trend.content.companyName }}</span
                      >
                    </div>
                    <div class="trends-content">
                      {{ trend.content.content }}
                    </div>
                    <div class="trends-pictures">
                      <el-image
                        v-for="(image, index) in trend.content.pictures"
                        style="width: 100px; height: 100px; margin-right: 12px"
                        :src="image"
                        :key="index"
                        @click="
                          picArray = trend.content.pictures;
                          showIndex = index;
                          showViewer = true;
                        "
                      />
                    </div>
                    <div class="trends-operator">
                      <div
                        class="likeNums"
                        @click="publishChildComment(trend, false)"
                      >
                        <i class="iconfont icon-dianzan"></i
                        >{{ trend.likeNums }}
                      </div>
                      <div class="commentNums" @click="openParentReply(trend)">
                        回复
                      </div>
                    </div>
                    <div class="replyContainer">
                      <el-input
                        v-if="trend.reply"
                        v-model="trend.replyText"
                        @input="$forceUpdate()"
                        :placeholder="`评论${
                          trend.username ? trend.username : '匿名用户'
                        }...`"
                      >
                        <template slot="append">
                          <emotions
                            @chooseEmotion="
                              (emotion) =>
                                handleChildCommentEmotion(emotion, trend)
                            "
                          ></emotions>
                          <el-button
                            style="
                              margin-left: 4px;
                              border-left: 1px solid #dcdfe6;
                              border-radius: 0px;
                            "
                            @click="publishChildComment(trend, true)"
                            >评论</el-button
                          >
                        </template>
                      </el-input>

                      <div class="replyList" v-if="trend.content.children">
                        <div
                          class="replies"
                          v-for="(reply, rIndex) in trend.content.showAll ||
                          trend.content.total <= 2
                            ? trend.content.children
                            : trend.content.children.slice(0, 2)"
                          :key="rIndex"
                        >
                          <img :src="reply.avatar" class="replyUserAvatar" />
                          <div
                            v-if="!reply.toAvatar || !reply.toUsername"
                            class="reply-area"
                          >
                            {{ reply.username }}:
                            <span class="commentContent">
                              {{
                                reply.visibility
                                  ? reply.content
                                  : "该评论已删除"
                              }}
                            </span>
                          </div>
                          <div v-else class="reply-area">
                            {{ reply.username }}
                            <i class="el-icon-caret-right"></i>
                            {{ reply.toUsername }}:
                            <span class="commentContent">
                              {{
                                reply.visibility
                                  ? reply.content
                                  : "该评论已删除"
                              }}
                            </span>
                          </div>
                          <div class="reply-operator">
                            <span class="reply-time">{{
                              reply.createTime
                            }}</span>
                            <span class="reply-likeNum">
                              <i
                                class="iconfont icon-dianzan"
                                @click="
                                  publishSecondChildComment(trend, reply, false)
                                "
                              ></i>
                              {{ reply.likeNums }}</span
                            >
                            <span
                              class="reply-reply"
                              @click="openChildReply(reply)"
                              >回复</span
                            >
                          </div>
                          <div
                            class="replys"
                            v-if="reply.reply"
                            style="margin-top: 8px"
                          >
                            <el-input
                              v-model="reply.replyText"
                              :placeholder="`回复${reply.username}...`"
                              @input="$forceUpdate()"
                            >
                              <template slot="append">
                                <emotions
                                  @chooseEmotion="
                                    (emotion) =>
                                      handleChildCommentEmotion(emotion, reply)
                                  "
                                ></emotions>
                                <el-button
                                  style="
                                    margin-left: 4px;
                                    border-left: 1px solid #dcdfe6;
                                    border-radius: 0px;
                                  "
                                  @click="
                                    publishSecondChildComment(
                                      trend,
                                      reply,
                                      true
                                    )
                                  "
                                  >回复</el-button
                                >
                              </template>
                            </el-input>
                          </div>
                        </div>
                        <div
                          style="text-align: left; padding: 12px 0"
                          v-if="
                            trend.content.total > 10 && trend.content.showAll
                          "
                        >
                          <el-pagination
                            style="padding: 0"
                            prev-text="上一页"
                            next-text="下一页"
                            :current-page.sync="trend.content.current"
                            :total="trend.content.total"
                            layout="prev, pager, next"
                            @current-change="
                              (page) => getTrendsCommentList(trend, page)
                            "
                          >
                          </el-pagination>
                        </div>
                        <div
                          v-if="
                            trend.content.total > 2 && !trend.content.showAll
                          "
                        >
                          共{{ trend.content.total }}条回复，<span
                            style="color: #00a1d6; cursor: pointer"
                            @click="
                              trend.content.showAll = true;
                              $forceUpdate();
                            "
                            >点击查看</span
                          >
                        </div>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="22" v-else-if="trend.type.value == 2">
                    <div class="username">
                      {{ trend.username ? trend.username : "匿名用户" }}
                      <span class="createTime">{{ trend.createTime }}</span>
                      <span class="trendType">{{ trend.type.name }}</span>
                    </div>
                    <div class="trendLabels">
                      <span
                        v-for="(label, lIndex) in trend.content.labels"
                        :key="lIndex"
                        :class="[
                          lIndex == 0
                            ? 'orange'
                            : lIndex == 1
                            ? 'red'
                            : lIndex == 2
                            ? 'green'
                            : lIndex == 3
                            ? 'blue'
                            : lIndex == 4
                            ? 'sgreen'
                            : '',
                        ]"
                      >
                        {{ label }}
                        <span
                          style="padding: 0 5px; color: #828282 !important"
                          >{{
                            lIndex == trend.content.labels.length - 1 ? "" : "|"
                          }}</span
                        ></span
                      >
                    </div>
                    <div class="trendTitle">
                      <div class="title-text">{{ trend.content.title }}</div>
                      <div class="questionNum">
                        {{ trend.content.questions }}题
                      </div>
                    </div>
                    <div class="trends-content ellipsis">
                      {{ trend.content.interviewAfter }}
                    </div>
                    <div class="trends-pictures">
                      <el-image
                        v-for="(image, index) in trend.content.pictures"
                        style="width: 100px; height: 100px; margin-right: 12px"
                        :src="image"
                        :key="index"
                        @click="
                          picArray = trend.content.pictures;
                          showIndex = index;
                          showViewer = true;
                        "
                      />
                    </div>
                    <div class="trends-operator">
                      <div class="likeNums">
                        <i class="iconfont icon-dianzan"></i
                        >{{ trend.likeNums }}
                      </div>
                      <div class="commentNums">
                        <i class="iconfont icon-huifu"></i
                        >{{ trend.commentNums }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="22" v-else-if="trend.type.value == 3">
                    <div class="username">
                      {{ trend.username ? trend.username : "匿名用户" }}
                      <span class="createTime">{{ trend.createTime }}</span>
                      <span class="trendType">{{ trend.type.name }}</span>
                    </div>
                    <div style="margin-top: 8px">
                      <div class="companyName">
                        {{ trend.content.companyName }}
                      </div>
                      <div
                        :class="[
                          'isExplain',
                          trend.content.isExplain &&
                          trend.content.isExplain.value === 3
                            ? 'active'
                            : '',
                        ]"
                      >
                        {{
                          trend.content.isExplain &&
                          trend.content.isExplain.value === 3
                            ? "已解答"
                            : "待解答"
                        }}
                      </div>
                    </div>
                    <div class="question-title">
                      {{ trend.content.interviewQuestions }}
                    </div>
                    <div class="trends-operator">
                      <div class="likeNums">
                        <i class="iconfont icon-dianzan"></i
                        >{{ trend.likeNums }}
                      </div>
                      <div class="commentNums">
                        <i class="iconfont icon-huifu"></i
                        >{{ trend.commentNums }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="22" v-else-if="trend.type.value == 4">
                    <div class="username">
                      {{ trend.username ? trend.username : "匿名用户" }}
                      <span class="createTime">{{ trend.createTime }}</span>
                      <span class="trendType">{{ trend.type.name }}</span>
                    </div>
                    <div class="trendTitle">
                      <div class="title-text">{{ trend.content.title }}</div>
                    </div>
                    <div
                      class="trends-content renderContent"
                      v-html="trend.content.content"
                    ></div>
                    <div class="trends-pictures">
                      <el-image
                        v-for="(image, index) in trend.content.pictures"
                        style="width: 100px; height: 100px; margin-right: 12px"
                        :src="image"
                        :key="index"
                        @click="
                          picArray = trend.content.pictures;
                          showIndex = index;
                          showViewer = true;
                        "
                      />
                    </div>
                    <div class="trends-operator">
                      <div class="likeNums">
                        <i class="iconfont icon-dianzan"></i
                        >{{ trend.likeNums }}
                      </div>
                      <div class="commentNums">
                        <i class="iconfont icon-huifu"></i
                        >{{ trend.commentNums }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div class="no-trends" v-if="trendsList.length == 0">
              暂无动态！
            </div>
          </div>
        </div>
        <div v-if="activeTab == 'findJobs'" class="trends">
          <div class="publishArea">
            <el-tabs v-model="activeName">
              <el-tab-pane label="发动态" name="first">
                <trends-publish
                  type="findJobs"
                  @updateFindJobList="getFindJobList"
                ></trends-publish>
              </el-tab-pane>
            </el-tabs>
          </div>
          <div class="findJobsList">
            <div class="findJobHeader">
              <div class="title">
                {{
                  companyId != "" && searchCompanyKey != ""
                    ? `${searchCompanyKey} > `
                    : ""
                }}
                {{ titleText }}
              </div>
              <div class="searchCompany">
                <el-autocomplete
                  v-model="searchCompanyKey"
                  :fetch-suggestions="querySearch"
                  :trigger-on-focus="false"
                  placeholder="搜索公司"
                  :popper-append-to-body="false"
                  @input="searchLodash"
                  @select="selectCompany"
                  @keydown.native="companyId = ''"
                  @keydown.native.enter="selectDefaultCompany"
                  prefix-icon="el-icon-search"
                >
                  <template slot-scope="{ item }" v-if="companys.length > 0">
                    {{ item.name }}
                  </template>
                </el-autocomplete>
              </div>
            </div>

            <div class="filters">
              <div
                v-for="(filter, index) in filters"
                :key="index"
                :class="['filter', jobType == filter.status ? 'active' : '']"
                @click="selectFilter(filter)"
              >
                {{ filter.name }}
              </div>
            </div>
            <div class="trendsList" style="box-shadow: none">
              <div
                class="trendsComment"
                v-for="(trend, index) in findJobList"
                :key="index"
              >
                <!-- 最新消息/求职感受部分 -->
                <div
                  v-if="
                    trend.type.value == 1 ||
                    trend.type.value == 5 ||
                    trend.type.value == 6
                  "
                >
                  <el-row :gutter="16">
                    <el-col :span="2" style="text-align: right">
                      <img
                        v-if="trend.avatar"
                        :src="trend.avatar"
                        class="userAvatar"
                        alt=""
                        @click.stop="jumpUserPage(trend.userId)"
                      />
                      <div v-else class="anonymous">匿名</div>
                    </el-col>
                    <el-col :span="22">
                      <div class="username">
                        {{ trend.username ? trend.username : "匿名用户" }}
                        <span class="createTime">{{ trend.createTime }}</span>
                        <span
                          class="trendType orange"
                          v-if="trend.content.companyName"
                          >{{ trend.content.companyName }}</span
                        >
                      </div>
                      <div class="trends-content">
                        {{ trend.content.content }}
                      </div>
                      <div class="trends-pictures">
                        <el-image
                          v-for="(image, index) in trend.content.pictures"
                          style="
                            width: 100px;
                            height: 100px;
                            margin-right: 12px;
                          "
                          :src="image"
                          :key="index"
                          @click="
                            picArray = trend.content.pictures;
                            showIndex = index;
                            showViewer = true;
                          "
                        />
                      </div>
                      <div class="trends-operator">
                        <div
                          class="likeNums"
                          @click="publishChildComment(trend, false)"
                        >
                          <i class="iconfont icon-dianzan"></i
                          >{{ trend.likeNums }}
                        </div>
                        <div
                          class="commentNums"
                          @click="openParentReply(trend)"
                        >
                          回复
                        </div>
                      </div>
                      <div class="replyContainer">
                        <el-input
                          v-if="trend.reply"
                          v-model="trend.replyText"
                          @input="$forceUpdate()"
                          :placeholder="`评论${
                            trend.username ? trend.username : '匿名用户'
                          }...`"
                        >
                          <template slot="append">
                            <emotions
                              @chooseEmotion="
                                (emotion) =>
                                  handleChildCommentEmotion(emotion, trend)
                              "
                            ></emotions>
                            <el-button
                              style="
                                margin-left: 4px;
                                border-left: 1px solid #dcdfe6;
                                border-radius: 0px;
                              "
                              @click="publishChildComment(trend, true)"
                              >评论</el-button
                            >
                          </template>
                        </el-input>

                        <div class="replyList" v-if="trend.content.children">
                          <div
                            class="replies"
                            v-for="(reply, rIndex) in trend.content.showAll ||
                            trend.content.total <= 2
                              ? trend.content.children
                              : trend.content.children.slice(0, 2)"
                            :key="rIndex"
                          >
                            <img :src="reply.avatar" class="replyUserAvatar" />
                            <div
                              v-if="!reply.toAvatar || !reply.toUsername"
                              class="reply-area"
                            >
                              {{ reply.username }}:
                              <span class="commentContent">
                                {{
                                  reply.visibility
                                    ? reply.content
                                    : "该评论已删除"
                                }}
                              </span>
                            </div>
                            <div v-else class="reply-area">
                              {{ reply.username }}
                              <i class="el-icon-caret-right"></i>
                              {{ reply.toUsername }}:
                              <span class="commentContent">
                                {{
                                  reply.visibility
                                    ? reply.content
                                    : "该评论已删除"
                                }}
                              </span>
                            </div>
                            <div class="reply-operator">
                              <span class="reply-time">{{
                                reply.createTime
                              }}</span>
                              <span class="reply-likeNum">
                                <i
                                  class="iconfont icon-dianzan"
                                  @click="
                                    publishSecondChildComment(
                                      trend,
                                      reply,
                                      false
                                    )
                                  "
                                ></i>
                                {{ reply.likeNums }}</span
                              >
                              <span
                                class="reply-reply"
                                @click="openChildReply(reply)"
                                >回复</span
                              >
                            </div>
                            <div
                              class="replys"
                              v-if="reply.reply"
                              style="margin-top: 8px"
                            >
                              <el-input
                                v-model="reply.replyText"
                                :placeholder="`回复${reply.username}...`"
                                @input="$forceUpdate()"
                              >
                                <template slot="append">
                                  <emotions
                                    @chooseEmotion="
                                      (emotion) =>
                                        handleChildCommentEmotion(
                                          emotion,
                                          reply
                                        )
                                    "
                                  ></emotions>
                                  <el-button
                                    style="
                                      margin-left: 4px;
                                      border-left: 1px solid #dcdfe6;
                                      border-radius: 0px;
                                    "
                                    @click="
                                      publishSecondChildComment(
                                        trend,
                                        reply,
                                        true
                                      )
                                    "
                                    >回复</el-button
                                  >
                                </template>
                              </el-input>
                            </div>
                          </div>
                          <div
                            style="text-align: left; padding: 12px 0"
                            v-if="
                              trend.content.total > 10 && trend.content.showAll
                            "
                          >
                            <el-pagination
                              style="padding: 0"
                              prev-text="上一页"
                              next-text="下一页"
                              :current-page.sync="trend.content.current"
                              :total="trend.content.total"
                              layout="prev, pager, next"
                              @current-change="
                                (page) => getTrendsCommentList(trend, page)
                              "
                            >
                            </el-pagination>
                          </div>
                          <div
                            v-if="
                              trend.content.total > 2 && !trend.content.showAll
                            "
                          >
                            共{{ trend.content.total }}条回复，<span
                              style="color: #00a1d6; cursor: pointer"
                              @click="
                                trend.content.showAll = true;
                                $forceUpdate();
                              "
                              >点击查看</span
                            >
                          </div>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="no-trends" v-if="findJobList.length == 0">
                暂无动态！
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right-container">
        <div class="interviewExpression">
          <div class="title">经验感悟</div>
          <div
            class="interviewExp"
            v-for="(interviewExp, index) in trendsStatistics.experiences"
            :key="index"
          >
            <router-link :to="`/interviewExpDetail?id=${interviewExp.id}`">
              <div class="interviewTitle">{{ interviewExp.title }}</div>
              <div
                class="interviewDes renderContent"
                v-html="interviewExp.content"
              ></div>
            </router-link>
          </div>
          <router-link to="/interviewExpression">
            <div class="more">查看更多</div></router-link
          >
        </div>
      </div>
      <el-image-viewer
        v-if="showViewer"
        :initial-index="showIndex"
        :url-list="picArray"
        :on-close="closeViewer"
      />
    </div>
<!--    <uploadPDF></uploadPDF>-->
  </div>
</template>
<script>
import emotions from "@/utils/emotions/index";
import uploadPDF from "@/views/fixedComponents/uploadPDF";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import { mapState } from "vuex";
import trendsPublish from "./trendsPublish.vue";
import {
  getTrendsStatistics,
  focusObject,
  getTrendsList,
  getFindJobList,
  browseTrends,
} from "@/service/trends";
import { getUserDetail } from "@/service/user";
import {
  getTrendsComments,
  publishComment,
  getTrendDetail,
} from "@/service/comments";
import { searchCompanys } from "@/service/company";
import _ from "lodash";
export default {
  name: "trendsIndex",
  components: { trendsPublish, ElImageViewer, emotions, uploadPDF },
  data() {
    return {
      activeTab: "trends",
      activeName: "first",
      trendsDropText: "全部",
      trendsStatistics: {    
        experiences: [],
        companies: [],
      },
      currentPage: 1,
      total: 0,
      trendsList: [],
      picArray: [],
      showIndex: 0,
      showViewer: false,
      findJobList: [],
      filters: [
        {
          name: "全部",
          status: "",
        },
        {
          name: "最新信息",
          status: "1",
        },
        {
          name: "求职感受",
          status: "0",
        },
        {
          name: "New Grad",
          status: "3",
        },
        {
          name: "Internship",
          status: "2",
        },
        {
          name: "在职跳槽",
          status: "4",
        },
      ],
      activeFilter: 0,
      searchCompanyKey: "",
      companys: [],
      titleText: "全部",
      jobType: "",
      companyId: "",
      hoverUserInfo: {},
    };
  },
  computed: {
    ...mapState(["userInfo"]),
    computedHeight() {
      return window.innerHeight - 72 + "px";
    },
  },
  methods: {
    switchTab(type) {
      this.activeTab = type;
      this.total = 0;
      if (type == "trends") {
        this.getTrendsList();
      } else {
        this.getFindJobList();
      }
    },
    handleClick(tab) {
      if (tab.name == "second") {
        this.$router.push("/interviewEdit");
      }
    },
    handleCommand(command) {
      if (command == "all") {
        this.trendsDropText = "全部";
        this.getTrendsList();
      } else if (command == "focus") {
        this.trendsDropText = "关注";
      }
    },
    getTrendsStatistics() {
      getTrendsStatistics().then((res) => {
        if (res.success) {
          this.trendsStatistics = res.result;
          this.replaceImgToText();
        }
      });
    },
    focusCompany(id) {
      focusObject({
        superId: id,
        type: 1,
      }).then((res) => {
        if (res.success) {
          this.getTrendsStatistics();
          this.$message.closeAll();
          this.$message.success("操作成功!");
        }
      });
    },

    getTrendsList() {
      this.currentPage = 1;
      getTrendsList({ current: 1 }).then((res) => {
        if (res.success) {
          this.total = res.result.total;
          this.trendsList = res.result.records;
          this.replaceImgToText();
        }
      });
    },
    loadTrends() {
      if (this.trendsList.length >= this.total) {
        return;
      }
      this.currentPage = this.currentPage + 1;
      getTrendsList({ current: this.currentPage }).then((res) => {
        if (res.success) {
          this.total = res.result.total;
          this.trendsList = this.trendsList.concat(res.result.records);
          this.replaceImgToText();
        }
      });
    },
    load() {
      if (this.activeTab == "trends") {
        this.loadTrends();
      } else {
        this.loadFindJobs();
      }
    },
    getFindJobList() {
      this.currentPage = 1;
      getFindJobList({
        current: 1,
        type: this.jobType,
        companyId: this.companyId,
      }).then((res) => {
        if (res.success) {
          this.findJobList = res.result.records;
          this.total = res.result.total;
          this.replaceImgToText();
        }
      });
    },
    loadFindJobs() {
      if (this.findJobList.length >= this.total) {
        return;
      }
      this.currentPage = this.currentPage + 1;
      getFindJobList({
        current: this.currentPage,
        type: this.jobType,
        companyId: this.companyId,
      }).then((res) => {
        if (res.success) {
          this.total = res.result.total;
          this.findJobList = this.findJobList.concat(res.result.records);
          this.replaceImgToText();
        }
      });
    },
    closeViewer() {
      this.showViewer = false;
      this.picArray = [];
    },
    replaceImgToText() {
      this.$nextTick(() => {
        const renderArray = document.querySelectorAll(".renderContent");
        for (let i = 0; i < renderArray.length; i++) {
          let picArray = renderArray[i].querySelectorAll("img");
          for (let j = 0; j < picArray.length; j++) {
            let newNode = document.createTextNode("[图片]");
            console.log(picArray[j]);
            picArray[j].parentNode.replaceChild(newNode, picArray[j]);
          }
        }
      });
    },
    jumpInterviewExpression(id) {
      this.$router.push(`/interviewExpDetail?id=${id}`);
    },
    openParentReply(item) {
      item.reply = item.reply ? !item.reply : true;
      this.$forceUpdate();
    },
    openChildReply(item) {
      item.reply = item.reply ? !item.reply : true;
      this.$forceUpdate();
    },
    handleChildCommentEmotion(emotion, comment) {
      if (comment.replyText) {
        comment.replyText = comment.replyText + emotion;
      } else {
        comment.replyText = "" + emotion;
      }
      this.$forceUpdate();
    },
    publishChildComment(comment, isComment) {
      let commentType;
      switch (comment.type.value) {
        case 1:
          commentType = 0;
          break;
        case 5:
          commentType = 8;
          break;
        case 6:
          commentType = 9;
          break;
      }
      if (isComment && (!comment.replyText || comment.replyText == "")) {
        return this.$message.error("请输入评论内容！");
      }
      console.log(comment);
      const data = {
        content: isComment ? comment.replyText : "",
        superType: commentType,
        type: isComment,
        superId: comment.content.id,
        parentId: 0,
        commentId: 0,
      };
      publishComment(data).then((res) => {
        if (res.success) {
          this.$message.success(`${isComment ? "评论" : "点赞"}成功！`);
          this.updateTrend(comment);
          this.updateTrendDetail(comment);
          comment.replyText = "";
          this.$forceUpdate();
        } else {
          this.$message.info(res.message);
        }
      });
    },
    publishSecondChildComment(parentComment, childComment, isComment) {
      let parentCommentType;
      switch (parentComment.type.value) {
        case 1:
          parentCommentType = 0;
          break;
        case 5:
          parentCommentType = 8;
          break;
        case 6:
          parentCommentType = 9;
          break;
      }
      if (
        isComment &&
        (!childComment.replyText || childComment.replyText == "")
      ) {
        return this.$message.error("请输入内容！");
      }
      const data = {
        content: isComment ? childComment.replyText : "",
        superType: parentCommentType,
        type: isComment,
        superId: parentComment.content.id,
        parentId: childComment.id,
        commentId: childComment.id,
      };
      publishComment(data).then(async (res) => {
        if (res.success) {
          this.$message.success("评论成功！");
          this.updateTrend(parentComment);
          this.updateTrendDetail(parentComment);
          this.$forceUpdate();
        } else {
          this.$message.info(res.message);
        }
      });
    },
    updateTrend(trend) {
      let type;
      switch (trend.type.value) {
        case 1:
          type = 0;
          break;
        case 5:
          type = 8;
          break;
        case 6:
          type = 9;
          break;
      }
      trend.content.current = trend.content.current ? trend.content.current : 1;
      getTrendsComments(trend.content.id, trend.content.current, type).then(
        (res) => {
          if (res.success) {
            trend.content.children = res.result.records;
            trend.content.total = res.result.total;
            trend.content.showAll = true;
            this.$forceUpdate();
          }
        }
      );
    },

    updateTrendDetail(trend) {
      let type;
      switch (trend.type.value) {
        case 1:
          type = 0;
          break;
        case 5:
          type = 8;
          break;
        case 6:
          type = 9;
          break;
      }
      getTrendDetail(trend.content.id, type).then((res) => {
        if (res.success) {
          console.log(res);
          trend.likeNums = res.result.likeNums;
          trend.commentNums = res.result.commentNums;
        }
      });
    },
    getTrendsCommentList(trend, page) {
      trend.content.current = page;
      let type;
      switch (trend.type.value) {
        case 1:
          type = 0;
          break;
        case 5:
          type = 8;
          break;
        case 6:
          type = 9;
          break;
      }
      getTrendsComments(trend.content.id, trend.content.current, type).then(
        (res) => {
          if (res.success) {
            trend.content.children = res.result.records;
            trend.content.total = res.result.total;
            this.$forceUpdate();
          }
        }
      );
    },

    querySearch(queryString, cb, type) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(queryString == "" ? [] : this.companys);
      }, 1000);
    },
    searchLodash: _.debounce(function () {
      this.searchCompanys();
    }, 1000),
    searchCompanys() {
      searchCompanys(this.searchCompanyKey).then((res) => {
        if (res.success) {
          this.companys = res.result;
        }
      });
    },
    selectCompany(option) {
      this.searchCompanyKey = option.name;
      this.companyId = option.id;
      this.getFindJobList();
    },
    selectDefaultCompany() {
      if (this.companys.length > 0) {
        this.searchCompanyKey = this.companys[0].name;
        this.companyId = this.companys[0].id;
      } else {
        this.searchCompanyKey = "";
        this.companyId = "";
      }
      this.getFindJobList();
    },
    selectFilter(filter) {
      this.jobType = filter.status;
      this.titleText = filter.name;
      this.getFindJobList();
    },
    viewTrends(trend) {
      if (
        trend.type.value == 2 ||
        trend.type.value == 3 ||
        trend.type.value == 4
      ) {
        browseTrends(trend.id);
        window.open(trend.href);
      }
    },
    jumpUserPage(userId) {
      this.$router.push(`/userPage?userId=${userId}`);
    },
    getHoverUserInfo(userId) {
      getUserDetail(userId).then((res) => {
        if (res.success) {
          this.hoverUserInfo = res.result;
        }
      });
    },
    focusUser(id) {
      focusObject({
        superId: id,
        type: 0,
      }).then((res) => {
        if (res.success) {
          this.getHoverUserInfo(id);
          this.$message.closeAll();
          this.$message.success("操作成功!");
        }
      });
    },
  },
  mounted() {
    if (this.$route.query.type) {
      if (this.$route.query.type == "findJobs") {
        this.activeTab = this.$route.query.type;
        this.companyId = this.$route.query.companyId;
        this.searchCompanyKey = this.$route.query.companyName;
        this.jobType = this.$route.query.jobType;
        this.getFindJobList();
        this.titleText = this.filters.filter((item) => {
          return item.status == this.$route.query.jobType;
        })[0].name;
      }
    } else {
      this.getTrendsList();
    }

    this.getTrendsStatistics();
  },
};
</script>
<style scoped lang="scss">
#appContainer {
  min-height: 800px;
  background: #f8f8f8;
  padding-top: 16px;
}
.cson-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  overflow-y: auto;
  justify-content: center;
}
.middle-container {
  width: 800px;
  margin: 0 10px;
}
.left-container {
  width: 230px;
  .userInfo,
  .about-company {
    width: 100%;
    padding: 26px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 12px;
  }
  .userInfo {
    text-align: center;
    .userAvatar {
      cursor: pointer;
      img {
        width: 36px;
        height: 36px;
        border-radius: 50%;
      }
    }
    .userName {
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #34495e;
      line-height: 21px;
      margin-top: 8px;
      margin-bottom: 6px;
    }
    .userSign {
      margin: 0 auto;
      width: 100%;
      padding: 0 5%;
      font-size: 12px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: rgba(52, 73, 94, 0.6);
      line-height: 14px;
      padding-bottom: 14px;
      border-bottom: 1px solid #e4e4e4;
    }
    .focus {
      margin-top: 16px;
      text-align: left;
      font-size: 12px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #34495e;
      line-height: 12px;
      .focus-person {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
      }
      .focus-company {
        display: flex;
        justify-content: space-between;
      }
      .count {
        font-size: 12px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #0075f6;
        line-height: 12px;
      }
    }
  }
  .about-company {
    width: 100%;
    padding: 26px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 12px;
    .title {
      position: relative;
      font-weight: 700;
      color: #34495e;
      font-size: 16px;
      padding-left: 14px;
      line-height: 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 12px;
      margin-bottom: 12px;
      &::before {
        position: absolute;
        content: "";
        width: 3px;
        height: 20px;
        background: #0a7aff;
        border-radius: 2px;
        left: 0;
        top: 0;
      }
    }
    .more {
      font-size: 12px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #0075f6;
      line-height: 18px;
      display: inline-block;
    }
  }
}
.middle-container {
  .button-container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
    .activeTab {
      width: 49.5%;
      height: 42px;
      line-height: 42px;
      box-shadow: 0px 0px 5px 0px rgba(136, 148, 171, 0.2);
      border-radius: 4px;
      text-align: center;
      font-size: 14px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #34495e;
      cursor: pointer;
      &.active {
        border: 1px solid #0075f6;
        color: #0075f6;
      }
    }
  }
  .publishArea {
    padding: 20px 30px;
    padding-top: 8px;
    box-shadow: 0px 0px 5px 0px rgba(136, 148, 171, 0.2);

    background: #fff;
  }
}

.right-container {
  width: 250px;
  .interviewExpression {
    width: 100%;
    padding: 26px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 12px;
    .title {
      position: relative;
      font-weight: 700;
      color: #34495e;
      font-size: 16px;
      padding-left: 14px;
      line-height: 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 12px;
      margin-bottom: 12px;
      &::before {
        position: absolute;
        content: "";
        width: 3px;
        height: 20px;
        background: #0a7aff;
        border-radius: 2px;
        left: 0;
        top: 0;
      }
    }
    .more {
      font-size: 12px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #0075f6;
      line-height: 18px;
      display: inline-block;
    }
    .interviewExp {
      cursor: pointer;
      margin-bottom: 16px;
      .interviewTitle {
        font-size: 12px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #34495e;
        line-height: 18px;
      }
      .interviewDes {
        font-size: 12px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #828282;
        line-height: 18px;
      }
    }
  }
}
::v-deep .el-tabs__nav-wrap::after {
  background: transparent;
}
::v-deep .el-divider__text {
  background-color: #f8f8f8 !important;
}
.companies {
  .companyLogo {
    width: 36px;
    height: 36px;
  }
  .companyName,
  .follows {
    line-height: 18px;
  }
  .companyName {
    font-size: 12px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #34495e;
    line-height: 18px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .follows {
    font-size: 12px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: rgba(52, 73, 94, 0.5);
    line-height: 18px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .followBtn {
    width: 53px;
    height: 20px;
    background: rgba(0, 117, 246, 0.2);
    border-radius: 11px;
    border: 1px solid #0075f6;
    line-height: 20px;
    font-size: 12px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #0075f6;
    line-height: 18px;
    text-align: center;
    margin-top: 8px;
    cursor: pointer;
    &.grey {
      background: rgba(52, 73, 94, 0.2);
      color: rgba(52, 73, 94, 1);
      border: none;
    }
  }
  &:not(:last-child) {
    margin-bottom: 12px;
  }
}
.trendsList {
  padding: 20px 30px;
  padding-top: 0px;
  box-shadow: 0px 0px 5px 0px rgba(136, 148, 171, 0.2);
  background: #fff;
}
.trends {
  overflow-y: auto;
  .trendsComment {
    padding: 20px 0;
    cursor: pointer;
  }
  .trendsComment:not(:last-child) {
    border-bottom: 1px solid #e4e4e4;
  }
  .userAvatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    cursor: pointer;
  }
  .anonymous {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    font-size: 12px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #ffffff;
    line-height: 36px;
    background: rgba(10, 122, 255, 1);
    text-align: center;
    display: inline-block;
  }
  .username {
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #34495e;
    line-height: 21px;
  }
  .reply {
    float: right;
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: rgba(52, 73, 94, 0.5);
    line-height: 21px;
    cursor: pointer;
  }
  .replyContainer {
    margin-top: 8px;
    .replyList {
      margin-top: 8px;
      .replies {
        padding: 8px 0;
        .replyUserAvatar {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          margin-right: 6px;
        }
        .reply-area {
          display: inline-block;
          width: calc(100% - 40px);
          vertical-align: top;
          line-height: 24px;

          .commentContent {
            color: #222;
            word-break: break-all;
          }
        }
        .reply-operator {
          .reply-time {
            color: #34495e;
            margin-right: 24px;
          }
          .reply-likeNum {
            color: rgba(52, 73, 94, 0.7);
            cursor: pointer;
            margin-right: 24px;
          }
          .reply-reply {
            color: rgba(52, 73, 94, 0.7);
            cursor: pointer;
          }
        }
      }
    }
  }
  .createTime {
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: rgba(52, 73, 94, 0.5);
    line-height: 21px;
    margin-left: 12px;
  }
  .trendType {
    width: 50px;
    padding: 2px 12px;
    background: rgba(0, 117, 246, 0.2);
    border-radius: 9px;
    line-height: 16px;
    font-size: 12px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #0075f6;
    line-height: 18px;
    margin-left: 18px;
    &.orange {
      color: #fa6400 !important;
      background: rgba(250, 100, 0, 0.2);
    }
  }
  .orange {
    color: #fa6400 !important;
  }

  .blue {
    color: #2772db !important;
  }

  .green {
    color: #2ec1ac !important;
  }

  .red {
    color: #e02020 !important;
  }
  .sgreen {
    color: #2fbb1e !important;
  }
  .trendLabels {
    font-size: 12px;
    margin-top: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .trendTitle {
    display: flex;
    justify-content: flex-start;
    margin-top: 8px;
    .title-text {
      font-size: 14px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #34495e;
      line-height: 18px;
      max-width: calc(100% - 60px);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .questionNum {
      background: rgba(10, 122, 255, 0.1);
      font-size: 12px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #0a7aff;
      line-height: 18px;
      padding: 2px 6px;
      margin-left: 8px;
    }
  }
  .companyName {
    font-size: 12px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #fa6400;
    line-height: 18px;
    display: inline-block;
    margin-right: 10px;
  }
  .isExplain {
    font-size: 12px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #34c759;
    line-height: 18px;
    background: rgba(52, 199, 89, 0.1);
    padding: 3px 6px;
    display: inline-block;
    &.active {
      color: #e02020;
      background: rgba(224, 32, 32, 0.1);
    }
  }
  .question-title {
    margin-top: 8px;
    background: rgba(136, 148, 171, 0.1);
    border-radius: 2px;
    padding: 6px 10px;
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #292b2d;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .trends-content {
    font-size: 12px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #828282;
    line-height: 20px;
    margin-top: 8px;
    &.ellipsis {
      display: -webkit-box;
      -webkit-line-clamp: 2; /*让他显示两行*/
      -webkit-box-orient: vertical;
      word-break: break-all; /* 允许在单词内换行。*/
      overflow: hidden;
    }
  }
  .trends-pictures {
    margin-top: 9px;
  }
  .trends-operator {
    margin-top: 12px;
    color: #34495e !important;
    .iconfont {
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #828282;
      line-height: 16px;
    }
    .likeNums {
      display: inline-block;
      margin-right: 20px;
      color: #828282 !important;
      cursor: pointer;
      i {
        margin-right: 2px;
      }
    }
    .commentNums {
      display: inline-block;
      color: #828282 !important;
      cursor: pointer;
      i {
        margin-right: 4px;
      }
    }
  }
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  /*正常情况下滑块的样式*/
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    box-shadow: inset1px1px0rgba(0, 0, 0, 0.1);
  }
  /*鼠标悬浮在该类指向的控件上时滑块的样式*/
  &:hover::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    box-shadow: inset1px1px0rgba(0, 0, 0, 0.1);
  }
  /*鼠标悬浮在滑块上时滑块的样式*/
  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: inset1px1px0rgba(0, 0, 0, 0.1);
  }
  /*正常时候的主干部分*/
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    box-shadow: inset006pxrgba(0, 0, 0, 0);
    background-color: white;
  }
  /*鼠标悬浮在滚动条上的主干部分*/
  &::-webkit-scrollbar-track:hover {
    box-shadow: inset006pxrgba(0, 0, 0, 0.4);
    background-color: rgba(0, 0, 0, 0.01);
  }
  .no-trends {
    text-align: center;
    font-size: 20px;
    padding: 20px;
  }
}
.findJobsList {
  background: #fff;
  margin-top: 10px;
  border-radius: 4px;
  box-shadow: 0px 0px 5px 0px rgba(136, 148, 171, 0.2);
  .findJobHeader {
    height: 60px;
    line-height: 60px;
    display: flex;
    .title {
      width: 63%;
      margin-left: 36px;
      position: relative;
      padding-left: 8px;
      font-size: 16px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #34495e;
      &::before {
        position: absolute;
        content: "";
        width: 3px;
        height: 20px;
        background: #0a7aff;
        border-radius: 2px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .searchCompany {
      width: 30%;
    }
  }
  .filters {
    background: #f8f8f8;
    display: flex;
    justify-content: flex-start;
    padding: 12px 24px;
    .filter {
      padding: 2px 9px;
      margin: 0 9px;
      font-size: 14px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #999999;
      cursor: pointer;
      line-height: 14px;
      &.active {
        background: rgba(0, 117, 246, 0.2);
        border-radius: 10px;
        color: #0075f6;
      }
    }
  }
}
.renderContent {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.hoverUserInfo {
  padding: 14px;
  .hoverAvatar {
    width: 42px;
    height: 42px;
    vertical-align: top;
    border-radius: 50%;
  }
  .hoverUserName {
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #34495e;
    line-height: 21px;
  }
  .hoverDes {
    font-size: 12px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #828282;
    line-height: 16px;
    margin-top: 6px;
  }
  .hoverConcern {
    margin-top: 20px;
    background: rgba(0, 117, 246, 0.2);
    width: 144px;
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #0075f6;
    &.el-button--info {
      background: rgba(52, 73, 94, 0.2);
      border: none;
      color: #34495e;
    }
  }
}
</style>