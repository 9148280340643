<template>
  <!-- 上传PDF文件简历 -->
  <div>
    <el-dialog :visible.sync="dialogVisible" @close="closeEvent">
      <div slot="title">
        <div class="header">
          <img src="@/assets/img/resume3.png" alt="" />
          <div class="text">
            <div class="title">Google</div>
            <div class="des">面经直播+求职内部揭秘</div>
          </div>
        </div>
      </div>
      <div class="pdfContainer">
        <div class="upload">
          <div class="title">直播内容</div>
          <div class="des">
            直播面经题目+重点高频题目
            <br />
            揭秘Google面试
            <br />
            内推 面试内容 流程
            <br />
            难度 Return Offer薪资
            <br />
            带你知道真正的内部消息
          </div>
          <!-- <el-button type="primary" @click="uploadEvent">上传简历PDF</el-button> -->
        </div>
        <div class="qrcodeContainer">
          <div class="qrcodeTitle">扫码添加Edward老师！</div>
          <img class="qrcode" :src="qr.imageUrl" alt="" />
        </div>
      </div>
    </el-dialog>
    <el-popover placement="top-end" width="424" trigger="hover">
      <div class="hoverPDF">
        <div class="header">
          <img src="@/assets/img/resume3.png" alt="" />
          <div class="text">
            <div class="title">免费简历测评</div>
            <div class="des">北美求职简历提供修改建议</div>
          </div>
        </div>
        <div class="upload">
          <div class="title">直播内容</div>
          <div class="des">
            直播面经题目+重点高频题目
            <br />
            揭秘Google面试
            <br />
            内推 面试内容 流程
            <br />
            难度 Return Offer薪资
            <br />
            带你知道真正的内部消息
          </div>
          <!-- <el-button type="primary" @click="uploadEvent">上传简历PDF</el-button> -->
        </div>
        <div class="qrcodeContainer">
          <div class="qrcodeTitle">扫码添加Edward老师！</div>
          <img class="qrcode" :src="qr.imageUrl" alt="" />
        </div>
      </div>

      <img
        slot="reference"
        class="fixedIcon"
        src="@/assets/img/gift.png"
        alt=""
      />
    </el-popover>
    <el-upload
      :action="uploadUrl"
      :on-success="handleCvSuccess"
      :before-upload="beforeUpload"
      :show-file-list="false"
      :limit="1"
      accept=".pdf,.PDF"
      :headers="{ 'X-Access-Token': token }"
      :data="{ userId: userInfo.id }"
    >
      <i class="el-icon-plus" ref="uploadCv"></i>
    </el-upload>
  </div>
</template>
<script>
import { getCourseBanner } from "@/service";
import { mapState } from "vuex";
export default {
  name: "uploadPDF",
  data() {
    return {
      qr: {},
      dialogVisible: false,
      uploadUrl: process.env.VUE_APP_BASE_URL + "/common/uploadResume",
      token: window.localStorage.getItem("CSON_PAGE_TOKEN"),
    };
  },
  mounted() {
    if (!localStorage.getItem("initResume")) {
      this.dialogVisible = true;
    }
    this.getCourseBanner();
  },
  methods: {
    getCourseBanner() {
      getCourseBanner(10)
        .then((res) => {
          console.log(res);
          if (!res.result.code) {
            this.qr = res.result[0];
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    closeEvent() {
      localStorage.setItem("initResume", "1");
    },
    handleCvSuccess(res) {
      updateResume({
        id: this.userInfo.id,
        resume: res.result.objectKey,
      }).then((r) => {
        if (r.success) {
          this.$message.success("简历上传成功！");
        }
      });
    },
    beforeUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!isLt10M) {
        this.$message.error("上传简历大小不能超过 10MB!");
      }
      return isLt10M;
    },
    uploadEvent() {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/registerform");
        return;
      }
      this.$refs.uploadCv.click();
    },
  },
  computed: {
    ...mapState(["userInfo"]),
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-dialog {
  border-radius: 12px;
  overflow: hidden;
}
::v-deep .el-dialog__header {
  padding: 0;
}
.header {
  position: relative;
  .text {
    position: absolute;
    text-align: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .title {
      font-size: 32px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #ffffff;
      line-height: 48px;
    }
    .des {
      font-size: 16px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #ffffff;
      line-height: 24px;
    }
  }
  img {
    width: 100%;
    vertical-align: top;
  }
}
::v-deep .el-dialog__headerbtn {
  z-index: 9;
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: #fff;
  font-size: 30px;
}
.qrcodeTitle {
  font-size: 14px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #000000;
  line-height: 21px;
  margin-bottom: 10px;
}
.qrcode {
  width: 116px;
  height: 116px;
  vertical-align: top;
}
.qrcodeContainer {
  border: 1px solid rgba(116, 116, 128, 0.18);
  border-radius: 8px;
  padding: 22px 34px;
  text-align: center;
  margin-left: 80px;
}
.pdfContainer {
  display: flex;
  justify-content: center;
  .upload {
    margin-top: 12px;
    .title {
      font-size: 18px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #000000;
      line-height: 27px;
      margin-bottom: 12px;
    }
    .des {
      font-size: 14px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.5);
      line-height: 21px;
      margin-bottom: 12px;
    }
    .el-button {
      width: 200px;
    }
  }
}
.fixedIcon {
  position: fixed;
  right: 60px;
  bottom: 180px;
  cursor: pointer;
  width: 68px;
  height: 68px;
  vertical-align: top;
}
.hoverPDF {
  text-align: center;
  .header {
    .title {
      font-size: 28px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #ffffff;
      line-height: 42px;
    }
    .des {
      font-size: 14px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #ffffff;
      line-height: 21px;
    }
  }
  .qrcodeContainer {
    margin: 34px auto;
    display: inline-block;
    margin-top: 12px;
  }
  .el-button {
    width: 200px;
  }
  .upload {
    .title {
      font-size: 18px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #000000;
      line-height: 27px;
      margin-top: 30px;
      margin-bottom: 14px;
    }
    .des {
      font-size: 14px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.5);
      line-height: 21px;
      margin-bottom: 20px;
    }
  }
}
</style>