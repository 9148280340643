import { render, staticRenderFns } from "./uploadPDF.vue?vue&type=template&id=9c26a176&scoped=true"
import script from "./uploadPDF.vue?vue&type=script&lang=js"
export * from "./uploadPDF.vue?vue&type=script&lang=js"
import style0 from "./uploadPDF.vue?vue&type=style&index=0&id=9c26a176&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c26a176",
  null
  
)

export default component.exports