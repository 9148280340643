<template>
  <!-- 发布动态 -->
  <el-row style="margin-top: 24px">
    <el-col :span="2" style="text-align: right">
      <img
        :src="userInfo && userInfo.avatar"
        alt=""
        style="
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-right: 12px;
        "
      />
    </el-col>
    <el-col :span="22">
      <div v-if="type == 'findJobs' && trendsFocus" style="margin-bottom: 12px">
        <el-radio-group v-model="suggestionType" size="small">
          <el-radio :label="0" border>Internship</el-radio>
          <el-radio :label="1" border>New Grad</el-radio>
          <el-radio :label="2" border>在职跳槽</el-radio>
        </el-radio-group>
      </div>
      <el-input
        v-if="!trendsFocus"
        placeholder="写下你的动态..."
        @focus="focusTrends"
      ></el-input>
      <el-input
        v-if="trendsFocus"
        ref="trendsTextarea"
        type="textarea"
        v-model="comment"
        rows="4"
        placeholder="写下你的动态..."
      ></el-input>
      <el-row style="min-height: 10px">
        <el-upload
          multiple
          :action="uploadUrl"
          ref="upload"
          list-type="picture-card"
          :on-success="handleCommentPicSuccess"
          :before-upload="beforeAvatarUpload"
          :limit="9"
          accept=".jpg, .jpeg, .png, .gif"
          :data="{ needCut: true, isPublic: true }"
          :headers="{ 'X-Access-Token': token }"
          :on-remove="removeFile"
        >
          <i class="el-icon-plus" ref="uploadComPic"></i>
        </el-upload>
      </el-row>
      <div v-if="trendsFocus" class="functionalComments">
        <div>
          <emotions @chooseEmotion="handleEmotions"></emotions>
          <el-button style="padding: 4px" @click="uploadPics">
            <i class="el-icon-picture"></i>
          </el-button>
          <el-button
            type="primary"
            style="float: right; padding: 4px"
            @click="publishTrends"
          >
            发布
          </el-button>
          <el-checkbox style="float: right" v-model="anonymity"
            >匿名发布</el-checkbox
          >
        </div>
      </div>
    </el-col>
  </el-row>
</template>
<script>
import { mapState } from "vuex";
import emotions from "@/utils/emotions/index";
import { publishTrends } from "@/service/trends";
import { postSuggestion } from "@/service/provideResource";
export default {
  name: "trendsPublish",
  props: {
    type: {
      type: String,
      default: "",
    },
  },
  components: { emotions },
  data() {
    return {
      trendsFocus: false,
      comment: "",
      uploadUrl: process.env.VUE_APP_BASE_URL + "/common/upload",
      commentsPic: [],
      token: window.localStorage.getItem("CSON_PAGE_TOKEN"),
      suggestionType: 0,
      anonymity: false,
    };
  },
  methods: {
    focusTrends() {
      this.trendsFocus = true;
      this.$nextTick(() => {
        this.$refs.trendsTextarea.focus();
        if (this.type == "findJobs") {
          if (localStorage.getItem("userJobType")) {
            this.suggestionType = Number(localStorage.getItem("userJobType"));
          }
        }
      });
    },
    handleEmotions(emotion) {
      this.comment = this.comment + emotion;
    },
    uploadPics() {
      this.$refs.uploadComPic.click();
    },
    handleCommentPicSuccess(res) {
      this.commentsPic.push(res.result.objectKey);
    },
    beforeAvatarUpload(file) {
      console.log(file);
      if (this.commentsPic.length >= 9) {
        return this.$message.error("最多只能上传9张图片!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    removeFile(file) {
      let removeIndex = this.commentsPic.indexOf(
        file.response.result.accessibleUrl
      );
      this.commentsPic.splice(removeIndex, 1);
      console.log(this.commentsPic);
    },
    publishTrends() {
      if (this.comment == "") {
        return this.$message.error("发布动态不能为空！");
      }
      if (this.type == "") {
        const data = {
          type: 1,
          content: this.comment,
          pictures: this.commentsPic,
          anonymity: this.anonymity,
        };
        publishTrends(data).then((res) => {
          if (res.success) {
            this.comment = "";
            this.commentsPic = [];
            this.anonymity = false;
            this.trendsFocus = false;

            this.$refs.upload.clearFiles();
            this.$message.success("发布动态成功!");
            this.$emit("updateTrendsList", true);
          }
        });
      } else if (this.type == "findJobs") {
        let data = {
          companyId: "",
          type: 0,
          jobType: this.suggestionType,
          anonymity: this.anonymity,
          content: this.comment,
          pictures: this.commentsPic,
        };
        postSuggestion(data).then((res) => {
          if (res.success) {
            localStorage.setItem("userJobType", this.suggestionType);
            this.comment = "";
            this.commentsPic = [];
            this.anonymity = false;
            this.trendsFocus = false;
            this.$refs.upload.clearFiles();
            this.$message.success("发布动态成功!");
            this.$emit("updateFindJobList", true);
          }
        });
      }
    },
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  mounted() {},
};
</script>
<style scoped lang="scss">
::v-deep .el-upload.el-upload--picture-card {
  display: none;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item {
  margin-top: 8px;
}
.el-radio.is-bordered {
  ::v-deep .el-radio__input {
    display: none;
  }
  ::v-deep .el-radio__label {
    padding-left: 0;
  }
}
::v-deep .el-radio--small.is-bordered {
  padding: 8px 10px;
  border-radius: 16px;
}
::v-deep .el-radio {
  margin-right: 0px;
}
</style>